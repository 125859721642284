h1 {
    text-align: center;
}

.upload_div {
    height: 20vh;
    padding-bottom: 5vh;
}

#original_svg {
    text-align: center;
}

.input-block {
    padding-top: 1vh;
    padding-bottom: 1vh;
}

.input-number-container {
    padding: 1em;
}